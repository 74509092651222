import React, { useEffect, useState } from 'react'
import './operatorHome.scss'
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Alert, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Product from '../../../components/operatorCom/product/Product';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser, logoutUser, userHistory } from '../../../action/userActions';
import { getProducts } from '../../../action/productAction';
import { getQrUrl, resetQrUrl } from '../../../action/qrActions';
import MenuIcon from '@mui/icons-material/Menu';

import { saveAs } from 'file-saver'
import SideBar from '../../../components/sideBar/SideBar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import axios from "../../../api/api";
import TemplateCard from './TemplateCard';



const OperatorHome = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);
    const { products } = useSelector(state => state.productReducer);




    ///////////////////////////////////////////////////////// qrData ///////////////////////////////////
    const [qrData, setQrData] = useState({
        product_id: "",
        factory_operator_id: "",
        quantity: 1,
        serial_no: "",
        QR_size: "",
        QR_copies: 1,
        business_id: ""
    });

    const handleChange = (e) => {
        setQrData({ ...qrData, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (!user) {
            dispatch(loadUser())
        }
    }, [])

    const [userData, setUserData] = useState()


    useEffect(() => {
        if (user) {
            setUserData(user)
        }
    }, [user])







    /////////////////////////////////////////////////// quantity /////////////////////////////////////

    const [quantity, setQuantity] = useState(1);

    const dec = () => {
        if (quantity > 1) {
            setQuantity(Number(quantity) - 1);
            setQrData({ ...qrData, "quantity": Number(quantity) - 1 });
        } else {
            setQuantity(1);
            setQrData({ ...qrData, "quantity": 1 });
        }
    }
    const inc = () => {
        if (!quantity) {
            setQuantity(1);
            setQrData({ ...qrData, "quantity": 1 });

        } else {
            setQuantity(Number(quantity) + 1);
            setQrData({ ...qrData, "quantity": Number(quantity) + 1 });
        }
    }

    const handleQuantity = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        // console.log("New value", result)

        if (result == 0) {
            setQuantity("");
            setQrData({ ...qrData, "quantity": Number(result) });
        } else {
            setQuantity(Number(result));
            setQrData({ ...qrData, "quantity": Number(result) });
        }
    }


    /////////////////////////////////////////////////// Qr copy /////////////////////////////////////

    const [copy, setCopy] = useState(1);

    const dec1 = () => {

        if (copy > 1) {
            setCopy(Number(copy) - 1);
            setQrData({ ...qrData, "QR_copies": Number(copy) - 1 });
        } else {
            setCopy(1);
            setQrData({ ...qrData, "QR_copies": 1 });
        }
    }
    const inc1 = () => {
        if (!copy) {
            setCopy(1);
            setQrData({ ...qrData, "QR_copies": 1 });

        } else {
            setCopy(Number(copy) + 1);
            setQrData({ ...qrData, "QR_copies": Number(copy) + 1 });
        }
    }

    const handleCopy = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        if (result == 0) {
            setCopy("");
            setQrData({ ...qrData, "QR_copies": Number(result) });
        } else {
            setCopy(Number(result));
            setQrData({ ...qrData, "QR_copies": Number(result) });
        }
    }



    ///////////////////////////////////////////////////////// product ////////////////////////////////////////

    const [showProduct, setProduct] = useState(false);
    const [showTemplate, setShowTemplate] = useState(false);
    const [productName, setProductName] = useState("");

    const [productArray, setProductArray] = useState([
        {
            product_name: "",
            product_model: "",
            images: [{ image: "" }]
        }
    ]);
    const [templateName, setTemplateName] = useState("")
    const [templateArray, setTemplateArray] = useState([
        {
            template_name: "",
            // product_model: "",
            // images: [{ image: "" }]
        }
    ]);

    const closeAll = () => {
        setProduct(false);
    }
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null)


    // const fun = (data) => {
    //     setProductName(data?.product_name);
    //     setQrData({ ...qrData, "product_id": data?.id })
    //     setSelectedProduct(data?.id);
        
    //     dispatch(userHistory(data?.id))
    //     .then(()=>{
    //         setQrData({ ...qrData, serial_no: history?.serial_no });
            
    //     })
    //     .catch((error)=>{
    //         console.log(error)
    //         if(error === "No data available"){
    //             setQrData({ ...qrData, serial_no: "" });
    //         }
    //     })
    //     closeAll();

    // }
    // const fun = (data) => {
    //     setProductName(data?.product_name);
    //     setQrData({ ...qrData, "product_id": data?.id });
       
        
    //      setSelectedProduct(data?.id);

    //     // let matchingTemplate ; 
    
    //     // dispatch(userHistory(data?.id))
    //     //     .then((data) => {
    //     //           console.log(data , "data in history")
              
    //     //         // Set the product name
    //     //         // setQrData({ ...qrData, serial_no: historyData?.serial_no });
    
    //     //         // Check if there's a matching template in dataTemplate
    //     //         //  matchingTemplate = dataTemplate.find(
    //     //         //     (template) => template.template_id === history?.last_template_id
    //     //         // );
    //     //         // alert(matchingTemplate)
    //     //         // if (matchingTemplate) {
    //     //         //     setTemplateName(matchingTemplate.template_name);
    //     //         // }
     
                
    //     //     })
    //     //     .catch((error) => {
    //     //         console.log(error);
    //     //         if (error === "No data available") {
    //     //             setQrData({ ...qrData, serial_no: "" });
    //     //             if (matchingTemplate) {
    //     //                 setTemplateName(matchingTemplate.template_name);
    //     //             } else {
    //     //                 // If no matching template is found, set a default value
    //     //                 setTemplateName(dataTemplate[0]?.template_name);
    //     //             }
    //     //         }
    //     //     });
       
    //     dispatch(userHistory(data?.id)).then((history)=>{

    //         console.log(history , "data history")
    //         setQrData({ ...qrData, "product_id": data?.id });
    //         let  matchingTemplate = dataTemplate.find(
    //             (template) => template.template_id === history?.last_template_id
    //             );
    //             alert(matchingTemplate)
    //             setTemplateName(matchingTemplate.template_name);
               
    //     }).catch((error)=>{
    //         if(error === "No data available")
    //         {
    //             setQrData({ ...qrData, serial_no: "" });
    //             setTemplateName(dataTemplate[0]?.template_name);
              
    //         }
    //     })
     
           
    
    //     closeAll();
    // };
    useEffect(() => {
        // console.log(qrData.product_id, "qr data product id");
    }, [qrData.product_id]);
    

    
    const templates = (data) => {
        setTemplateName(data?.template_name);
        setSelectedTemplate(data?.template_id)
        // setQrData({ ...qrData, "product_id": data.id })
        // setSelectedProduct(data.id);
        // closeAll();
        // dispatch(userHistory(selectedProduct));
        // dispatch(userHistory(data?.id)).then((history)=>{
        //     console.log(history , "data history")
           
        // }).catch((error)=>{
        //     if(error === "No data available")
        //     {

        //        let  matchingTemplate = dataTemplate.find(
        //               (template) => template.template_id === history?.last_template_id
        //               );
        //               setTemplateName(dataTemplate[0]?.template_name);
        //     }
        // })

    }

    const fun = async (data) => {
        setProductName(data?.product_name);
        setQrData({ ...qrData, "product_id": data?.id });
        
        try {
          // Fetch history data based on the product ID
          const historyData = await dispatch(userHistory(data?.id));
        //   console.log(historyData , "history data")
      
          // Check if there's a matching template based on the "last_template_id" field
          const productData = dataTemplate.filter((obj) => {
            // console.log(obj , "object")
            return historyData.last_template_id == obj.template_id
        })
        //   console.log(productData[0]?.template_name , "matching template")
        //   console.log(dataTemplate , "template array")
      
          if (productData[0]?.template_name ) {
            setTemplateName(productData[0]?.template_name);
          } else {
            // If no matching template is found, set a default value
            setTemplateName(productData[0]?.template_name);
          }
      
          // Set the serial number and other fields based on history data
          setQrData({
            ...qrData,
            serial_no: historyData.serial_number,
            quantity: historyData.quantity,
            product_id: historyData.product_id,
            QR_size: historyData.QR_size ? historyData.QR_size : "", 
            QR_copies: historyData.QR_copies
          });
      
          closeAll();
        } catch (error) {
          console.error("Error fetching user history:", error);
          if (error === "No data available") {
            // Handle the case when there's no history data available
            setQrData({
              ...qrData,
              serial_no: "",
              quantity: 1,
              product_id: data?.id,
              QR_size: "",
              QR_copies: 1
            });
            setTemplateName(dataTemplate[0]?.template_name);
          } else {
            // Handle other error scenarios
            // You can display an error message or perform other actions here
           
          }
        }
      };
      



    /////////////////////////////// authentication ///////////////////////////////

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);


    //////////////////////////// history ////////////////////////////////////////////////////
    const { history } = useSelector(state => state.operatorHistory);
    // console.log(selectedProduct, "selected product")
   
    useEffect(() => {
        if (!history) {
            // Update the user history when the component loads
            dispatch(userHistory()).then((historyData)=>{
                // console.log(historyData , "hisyoryyyyyyy")
            }).catch((error)=>{
                // alert(error)
            })
        }



    }, []);
    const [dataTemplate, setDataTemplate] = useState([])
  
   
    useEffect(() => {
        if (history) {
            // console.log("history", history.last_template_id)
       
           
           
            setTimeout(() => {
                setQrData({ ...qrData, QR_copies: history.QR_copies, serial_no: history.serial_number , QR_size: history.QR_size ? history.QR_size : "", quantity: history.quantity, product_id: history?.product_id })
                setCopy(history.QR_copies);
                setQuantity(history.quantity)

            }, 100);
        }
    }, [history]);


    //////////////////////////////////-------------setProduct------------/////////////////////////
    useEffect(() => {
        if (products && history) {
            const productData = products.filter((obj) => {
                // console.log(obj , "objjj")
                return history?.product_id == obj?.id
            })
            // alert(productData[0])
            setProductName(productData[0]?.product_name)

        }
       
    }, [products, history]);

    useEffect(() => {
        if (dataTemplate && history) {
            const productData = dataTemplate.filter((obj) => {
                return history?.last_template_id == obj?.template_id
            })
            // console.log(productData , "produ data")
            setTemplateName(productData[0]?.template_name)
            setSelectedTemplate(productData[0]?.template_id)

        }
    }, [dataTemplate, history]);
    
    // useEffect(() => {
    //     if (dataTemplate && history) {
    //         const templateArray = templateArray.filter((obj) => {
    //             return history.last_template_id == obj.id
    //         })
    //         setTemplateName(templateArray.template_name)

    //     }
    // }, [dataTemplate, history]);
 



    ////////////////////////-------------- Alert -----------------------/////////////////////////////////
    const [alertMessage, setAlertMessage] = useState(false);

    // const continueFun = () => {
    //     setAlertMessage(false);


    //     setQrData({ ...qrData, "factory_operator_id": user?.id })
    //     dispatch(getQrUrl({ ...qrData, "factory_operator_id": user?.id }));



    //     setQrData({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id });
    //     // console.log(user.company_id, "company id")
    //     dispatch(getQrUrl({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id }, "product_qr")).then(async (data) => {
    //         // console.log("Data from getQrUrl:", data);
    //         alert(data)

    //       }).catch((err) => {
    //         // console.log(err)
    //         toast.error(err.response.data)
    //         dispatch(resetQrUrl())
    //     })
    //     dispatch(getQrUrl({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id }, "master_qr")).then(async (data) => {
    //         // console.log("Data from getQrUrl:", data);
    //         alert(data)


    //         window.location.reload()
    //         await toast.success("hello"); 


    //       }).catch((err) => {
    //         // console.log(err)
    //         toast.error(err.response.data)
    //         dispatch(resetQrUrl())
    //     })

    // }


    const continueFun = async () => {
        setAlertMessage(false);

        try {
            const qrType = productQr ? "product_qr" : "master_qr";
            const response = await dispatch(getQrUrl({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id , "template_id": selectedTemplate}, qrType));

            // console.log(`Data from ${qrType}:`, response);
            // alert(response)
            toast.success("Qr Generated Successfully");


            // window.location.reload()

        } catch (err) {
            // console.log(err);
            toast.error(err);
            dispatch(resetQrUrl());
        }
    };




    ////////////////////////////////////// redux ///////////////////////////////////////


    useEffect(() => {
        if (user) {
            dispatch(getProducts(user.factory_id));
            setQrData({ ...qrData, "factory_operator_id": user?.id, "business_id": user?.company_id });
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (products) {
            setProductArray(products);
        }
    }, [products]);





    // const generateQr = () => {


    //     if (productQr && qrData.product_id && qrData.QR_copies && qrData.quantity && qrData.serial_no) {

    //         if (qrData.QR_copies > 3 || qrData.quantity > 100) {
    //             setAlertMessage(true);
    //         } else {
    //             setQrData({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id });
    //             const qrType = "product_qr";
    //             dispatch(getQrUrl({ ...qrData, "factory_operator_id": user?.id, "business_id": user.company_id }, qrType)).then((data) => {
    //                 // console.log("success" ,data)
    //                 toast.success("Qr generated succesfully")

    //             }).catch((err) => {
    //                 // console.log(err, "err is opertor home")
    //                 toast.error(err.response.data)
    //             })
    //         }

    //     } else if (masterQr && qrData.quantity && qrData.QR_copies && qrData.serial_no) {

    //         if (qrData.QR_copies > 3 || qrData.quantity > 100) {
    //             setAlertMessage(true);
    //         } else {
    //             dispatch(getQrUrl({ serial_no: qrData.serial_no, quantity: qrData.quantity, QR_copies: qrData.QR_copies }, "master_qr"))
    //         }
    //     }
    //     else {
    //         toast.error("Error: Please fill all required field !", { theme: "colored", autoClose: 2000 });
    //     }

    // }
    const generateQr = async () => {
        const newQuantity = Number(quantity);
        const newCopy = Number(copy);
        
        if (productQr && qrData.product_id && newCopy && newQuantity && qrData.serial_no ) {
            if (newCopy > 3 || newQuantity > 100) {
                setAlertMessage(true);
            } else {
                try {
                    // console.log(qrData.product_id , "product id")
                    const updatedQrData = {
                        ...qrData,
                        "factory_operator_id": user?.id,
                        "business_id": user.company_id,
                        "template_id": selectedTemplate
                    };
                    const qrType = "product_qr";
                    const response = await dispatch(getQrUrl(updatedQrData, qrType));
                    // console.log("success", response);
                    toast.success("Qr generated successfully");
                    

                    // window.location.reload()


                } catch (error) {
                    console.error("Error generating QR:", error);
                    toast.error(error);
                    // window.location.reload()
                    dispatch(resetQrUrl());

                }
            }
        } else if (masterQr && newQuantity && newCopy && qrData.serial_no) {
            if (newCopy > 3 || newQuantity > 100) {
                setAlertMessage(true);
            } else {
                try {
                    
                    const updatedQrData = {
                        ...qrData,
                        "factory_operator_id": user?.id,
                        "business_id": user.company_id,
                        "template_id": selectedTemplate
                    };
                    const qrType = "master_qr";
                    const response = await dispatch(getQrUrl(updatedQrData, qrType));
                    // console.log("success", response);
                    toast.success("Qr generated successfully");
                } catch (error) {
                    console.error("Error generating QR:", error);
                    toast.error(error.response?.data);
                }
            }
        } else {
            toast.error("Error: Please fill all required fields!", {
                theme: "colored",
                autoClose: 2000
            });
        }
    };





    const { qrUrl, qrLoading } = useSelector(state => state.qrReducer);

    useEffect(() => {
        if (qrUrl) {
            saveAs(qrUrl, "qrPdf");
            setQrData({
                product_id: "",
                factory_operator_id: user.id,
                quantity: 1,
                serial_no: "",
                QR_copies: "",
                QR_size: ""
            });
            setProductName("");
            dispatch(resetQrUrl());
            dispatch(userHistory());
        }
    }, [qrUrl]);



    ///////////////////////////---------Side Bar --------///////////////////////////

    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }





    /////////////////////////////////////////////////// Qr Swictching /////////////////////////////////////
    const [masterQr, setMasterQr] = useState(false);
    const [productQr, setProductQr] = useState(true);
    const [template, setTemplate] = useState(true)

    const activeMasterQr = () => {
        setMasterQr(true);
        setProductQr(false);

        //---------------------- reset data ----------------//
        setQrData({ ...qrData, "serial_no": "", "quantity": 1, "QR_copies": 1 });
        setCopy(1);
        setQuantity(1);
    }

    const activeProductQr = () => {
        setMasterQr(false);
        setProductQr(true);
        setTemplate(true)

        // ------------------------------------ history ---------------------------------//
        setQrData({ ...qrData, QR_copies: history.QR_copies, serial_no: history.serial_number, QR_size: history.QR_size ? history.QR_size : "", quantity: history.quantity, product_id: history.product_id })
        setCopy(history.QR_copies);
        setQuantity(history.quantity)
    }

    //////////////////////////////------------------------- master Qr -----------------------////////////////////////////////////////

 
    const fetchLabels = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
            const { data } = await axios.post(`/getAllGlobalTemplates`, { token: token }, config);
            // console.log(data, "USER")
            // console.log(data[0].link_products, "link_products");
            // console.log(markAsDefaults, "defaults")
            // console.log(data, "data")
            setDataTemplate(data)


        } catch (error) {
            // console.log(error);
        }
    }
    useEffect(() => {
        fetchLabels()
    }, [])
    // useEffect(() => {
    //     if (dataTemplate && history) {
    //         const matchingTemplate = dataTemplate.find((template) => template.id === history.last_template_id);
    //         if (matchingTemplate) {
    //             setTemplateName(matchingTemplate.template_name);
    //         } else {
    //             // Set a default template name or handle the case when no matching template is found
    //             setTemplateName(dataTemplate[0].template_name);
    //         }
    //         console.log(matchingTemplate, "matching template");
    //     }
    // }, [dataTemplate, history]);
    
    



    return (
        <>


            {/* //////////////////////////////////////////////////// */}
            <ToastContainer />
            {/* //////////////////////////////////////////////////// */}


            <div className='main'>
                <div className='home' onClick={closeAll}>

                    <SideBar />


                    {/* ///////////////////////----------------- LOADER ----------------///////////////////// */}
                    {qrLoading ? <div className='qrLoader'>
                        <Bars
                            height="80"
                            width="80"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div> : null}

                    {/* ///////////////////////----------------- ALERT ----------------///////////////////// */}


                    {alertMessage ? <div className='alertCon'>
                        <div className='alertBox'>
                            <section> You have choosen a very large QR Quantity/Copies ...! </section>
                            <Button className='but1' variant='contained' onClick={() => setAlertMessage(false)}>Go Back</Button>
                            <Button className='but2' variant='contained' onClick={continueFun}>Continue</Button>
                        </div>
                    </div> : null}



                    <div id='inner'>


                        <div className='innerScroll'>

                            <div>

                                <button className='btn but1' onClick={openSideBar}> <MenuIcon /> </button>

                                <div className='head'>
                                    <div>Print QR code</div>
                                    {/* <section>
                                        Select a product and enter number of QR code
                                        you want to print and click download
                                    </section> */}
                                </div>


                                <form>


                                    <div className="form-group">
                                        <label>QR Type</label>
                                        <div className='grid2'>
                                            <div><Button className={productQr ? 'bt1' : 'bt2'} onClick={activeProductQr} variant='contained'>Product QR</Button></div>
                                            <div><Button className={masterQr ? 'bt1' : 'bt2'} onClick={activeMasterQr} variant='contained'>Master QR</Button> </div>
                                        </div>
                                    </div>


                                    {productQr && <div className="form-group select">
                                        <label> Product </label>
                                        <input type="text" className="form-control" readOnly style={{ cursor: "pointer" }} value={productName} placeholder="Select Product" onClick={(e) => { e.stopPropagation(); setProduct(!showProduct) }} />
                                        <ArrowDropDownIcon className='icon' />

                                        {showProduct ? <div className='selectProduct' onClick={(e) => e.stopPropagation()}>

                                            {productArray.map((val, index) => {
                                                return (
                                                    <Product key={index} data={val} fun={fun} showProduct = {showProduct} setProduct = {setProduct}/>
                                                );
                                            })}

                                        </div> : null}

                                    </div>}
                                    {template && <div className="form-group select">
                                        <label> Template </label>
                                        <input type="text" className="form-control" readOnly style={{ cursor: "pointer" }} value={templateName} placeholder="Select Template" onClick={(e) => { e.stopPropagation(); setShowTemplate(!showTemplate) }} />
                                        <ArrowDropDownIcon className='icon' />

                                        {showTemplate ? <div className='selectProduct' onClick={(e) => e.stopPropagation()}>

                                            {dataTemplate.map((val, index) => {
                                                return (
                                                    <TemplateCard key={index} data={val} templates={templates} setShowTemplate = {setShowTemplate} showTemplate = {showTemplate}/>
                                                );
                                            })}

                                        </div> : null}

                                    </div>}



                                    <div className="form-group select">
                                        <label>Starting Serial Number</label>
                                        <input type="text" className="form-control" name="serial_no" style={{ paddingLeft: "10px" }} value={qrData.serial_no} placeholder='Enter first serial Number of product' onChange={handleChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>Quantity</label>
                                        <div className='grid1'>
                                            <div className='d1'> <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Quantity" value={quantity} onChange={handleQuantity} />  </div>
                                            <div className='d2'> <Button variant='contained' className='bt3' onClick={dec}><RemoveIcon fontSize='medium' /></Button> </div>
                                            <div className='d2'> <Button variant='contained' className='bt3' onClick={inc}><AddIcon fontSize='medium' /></Button> </div>
                                        </div>
                                    </div>


                                    <div className="form-group">
                                        <label>Number of copies</label>
                                        <div className='grid1'>
                                            <div className='d1'> <input type="text" className="form-control" aria-describedby="emailHelp" placeholder="Number of copies" value={copy} onChange={handleCopy} />  </div>
                                            <div className='d2'> <Button variant='contained' className='bt3' onClick={dec1}><RemoveIcon fontSize='medium' /></Button> </div>
                                            <div className='d2'> <Button variant='contained' className='bt3' onClick={inc1}><AddIcon fontSize='medium' /></Button> </div>
                                        </div>
                                    </div>

                                    {/* <div className="form-group select">
                                        <label>Qr size</label>
                                        <input type="text" className="form-control" name="QR_size" value={qrData.QR_size} placeholder='Enter qr size' onChange={handleChange} />
                                    </div> */}


                                </form>



                                <div className='genBox'> <Button className='generate' variant='contained' onClick={generateQr}>Generate QR</Button> </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OperatorHome