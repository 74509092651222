import { Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './../login/login.scss'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../action/userActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../../assests/onepoket_logo.png"
import axios from "../../api/api"


const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const [showPass, setShow] = useState(false);
  const [showPassConf, setShowConf] = useState(false);



  const [passwordMatch, setPasswordMatch] = useState("")
  const [users, setUsers] = useState({
    password: "",
    confirmPassword: ""
  });
  const validateForm = () => {

    let isValid = true;
    if (users.password === users.confirmPassword) {
      isValid = true
    }
    else {
      toast.error("Reset Password : Password does not match")
      isValid = false
    }
    // Add more validation checks for other fields as needed

    return isValid;
  };

  useEffect(() => {
    setPasswordMatch(users.password);
  }, [users.password]);

  const handleChange = (e) => {
    setUsers({ ...users, [e.target.id]: e.target.value });

  };

  console.log(users , "users")



  /////////////////////////////// authentication ///////////////////////////////



  ////////////////-------- Toast -//////////////////////////////
  const toast_fun = (error) => {
    toast.error(`Error: ${error}`, { theme: "colored", autoClose: 2000 });
  }
  const toggle = () => {
    setShow(!showPass);
  }
  const toggle1 = () => {
    setShowConf(!showPassConf);
  }
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Access query parameters
  const token = queryParams.get('token');
  ///////////////////////////////// redux /////////////////////////////////////////
  const submitData = async () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    try {
      const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
      setLoading(true);
      const { data } = await axios.post(`/reset-company-user-password`, { token: token, newPassword: passwordMatch }, config);
      console.log(data)
      setLoading(false)
      toast.success("Password updated successfully")
      navigate(`/login`)

    } catch (error) {
      // console.log(error);
      toast.error(error.response.data)
      setLoading(false)
    }
  }

  return (
    <>

      {/* //////////////////////////////////////////////////// */}
      <ToastContainer />
      {/* //////////////////////////////////////////////////// */}


      <div className=' main bg-[#F3F4F6] h-[450px]'>
        <div className='Gatekeeper_login rounded bg-white'>
          <div className='header flex items-center flex-col mt-[20px]'>
            <img src={Logo} className='w-[50px] h-[50px]' />
            <h1 className='pt-3'>Set new password</h1>
            <p className='text-[16px] font-normal leading-[21px] text-[#70819C] pt-2 w-5/6 mx-auto'>Your new password must different to previos password</p>
          </div>

          <form onSubmit={handleSubmit(submitData)}>
            <div className="form-group">
              <label className='text-[16px] font-semibold text-[#6B7280] leading-[21px] '>Password</label>
              <input type={!showPass ? "password" : "text"} placeholder='* * * * *' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' id="password"
                  value={users.password}
                  {...register("pass", {
                    onChange: (e) => {
                      handleChange(e);
                    },
                    required: "Password is required!",
                  })} />
              <LockOpenIcon className='icon1' />

              <span onClick={toggle}>
                {showPass ? <VisibilityOffIcon className='icon2' /> :
                  <VisibilityIcon className='icon2' />}
              </span>

              <div className='error'>{errors.password?.message}</div>

            </div>
            <div className="form-group">
              <label className='text-[16px] font-semibold text-[#6B7280] leading-[21px] '>Confirm Password</label>
              <input type={!showPassConf ? "password" : "text"} placeholder='* * * * *' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' id="confirmPassword"
                  value={users.confirmPassword}
                  {...register("confpass", {
                    onChange: (e) => {
                      handleChange(e);
                    },
                    required: "Password is required!",
                  })} />
              <LockOpenIcon className='icon1' />

              <span onClick={toggle1}>
                {showPass ? <VisibilityOffIcon className='icon2' /> :
                  <VisibilityIcon className='icon2' />}
              </span>

              <div className='error'>{errors.password?.message}</div>

            </div>
            <div className=' w-[100%] mt-10 '>
              <button
                className='w-[100%] bg-[#4F46E5] text-white p-5 h-11 rounded-md py-2 text-[14px] font-semibold leading-[21px] hover:bg-[#6366F1]'
                disabled={loading} // Disable the button while loading is true
              >
                <div className="flex items-center justify-center">
                  {loading ? (
                    <>
                      <CircularProgress color="inherit" size={20} style={{ marginRight: '10px' }} /> {/* Show CircularProgress while loading */}
                      Loading...
                    </>
                  ) : (
                    'Submit' // Show 'Sign In' text when not loading
                  )}
                </div>
              </button>
            </div>
            <div className=" m-6 text-center space-x-1">
              <span>Back to</span>
              <Link to='/login'>
                <span className="hover:underline">Sign in</span>
              </Link>
            </div>

          </form>

        </div>
      </div>
    </>
  )
}

export default ResetPassword