import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { loadUser } from './action/userActions'
import './index.css'
import Login from './pages/login/Login'
import QrHistory from './pages/operatorPages/generatedQr/QrHistory'
import GateHome from './pages/gatekeeperPages/gateHome/GateHome'
import OperatorHome from './pages/operatorPages/operatorHome/OperatorHome'
import PackerHome from './pages/packerPages/packerHome/PackerHome'
import Home from './pages/home/Home'
import Profile from './pages/profile/Profile'
import Password from './pages/profile/Password'
import ForgotPassword from './pages/forgot/ForgotPassword'
import ResetPassword from './pages/forgot/ResetPassword'

const App = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadUser(navigate));
  }, []);


  return (
    <Routes>

      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/forgot_password' element={<ForgotPassword />} />
      <Route path='/reset_user_password' element={<ResetPassword />} />


      {/* //////////////////////////////----------- operator -------------/////////////////////////// */}
      <Route path='/operator' element={<OperatorHome />} />
      <Route path='/generated_qr' element={<QrHistory />} />


      {/* //////////////////////////////----------- gatekeeper -------------/////////////////////////// */}
      <Route path='/gatekeeper' element={<GateHome />} />


      {/* //////////////////////////////----------- packer -------------/////////////////////////// */}
      <Route path='/packer' element={<PackerHome />} />


      {/* //////////////////////////////----------- profile -------------/////////////////////////// */}
      <Route path='/profile' element={<Profile />} />
      <Route path='/password_update' element={<Password />} />




    </Routes>
  )
}

export default App