import React, { useEffect, useRef, useState } from 'react'
import "./header.css"

import MenuIcon from '@mui/icons-material/Menu';
import { Badge, BadgeMark, Button, Drawer, Box } from '@mui/material';


import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccountCircle, BadgeOutlined, Close, Logout, Mail, MenuOpen, Person } from '@mui/icons-material';
import { HISTORY_FAIL } from '../../constants/userConstants';
import { logoutUser } from '../../action/userActions';
// import { HiMenuAlt2 } from "react-icons/hi"
// import { HiMenu } from "react-icons/hi"




const Header = ({ fun, clearBuffer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const openSideBar = () => {
    document.getElementById("sideBar").style.left = "0px";
}
  const handleMenuClick = () => {
    openSideBar()
  };

  // const { user } = useSelector((state) => state.user);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);

    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    };
  }, []);

  const { user } = useSelector(state => state.userReducer);

  useEffect(() => {
    if (user) {
      console.log("user", user)
    }
  }, [])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  // const userLogout = () => {

  //   setLoader(true)
  //   dispatch(logout()).then(() => {


  //   })
  // };

  const logout = () => {
    dispatch(logoutUser());
    dispatch({ type: HISTORY_FAIL })
}
  return (
    <>
      {/* <div className='gateHeader'>
        <div className='innerHead'>
          <span onClick={fun}><MenuIcon className='menuBt' fontSize='large' /></span>
          <Button className='clearBt' variant='outlined' onClick={clearBuffer}>clear data</Button>
        </div>
      </div> */}
      <div className='flex  items-center sticky top-0 '>


        <div className='user-dropdpwn  pt-7 w-full flex items-center justify-between space-x-6'>
          <div className='mx-3 cursor-pointer' onClick={handleMenuClick} >
            <MenuIcon size={20} />
          </div>

          <div className="relative inline-block text-left mr-12 " ref={dropdownRef} >
            <div className='flex items-center'>
              <div onClick={toggleDropdown} className='cursor-pointer'>
                <AccountCircle size={30} />
              </div>
              <div className='px-3'>
                <span>Admin</span>
              </div>
            </div>

            
           <div className='dropdown-container'>
           <div
              className={`origin-top-right absolute right-0  mt-2  w-64 left-[-170px] rounded-md shadow-lg  ring-1 bg-white ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none transition   ${isOpen ? 'ease-out duration-100 transform opacity-100 scale-y-100  ' : 'ease-in duration-100 transform opacity-0 scale-y-0'
                }` }  

            >
              {/* Dropdown content */}
              <div className="py-1 relative z-50 indexx">

                <div className='flex items-center mt-2 hover:bg-gray-100 hover:text-gray-900  py-1'>
                  <div className='px-3'>
                    <Mail size={20} />
                  </div>
                  <div className=' pb-2'>
                    <span className='text-[12px] lieading-[16px] font-normal text-[#6B7280]'>{user?.email}</span>
                  </div>
                </div>
                <div className='flex items-center mt-2 hover:bg-gray-100 hover:text-gray-900  py-1 cursor-pointer'>
                  <div className='px-3'>
                    <BadgeOutlined size={20} />
                  </div>
                  <div className=''>
                    <span className='text-[16px] lieading-[16px] font-normal text-[#6B7280]'>{user?.name}</span>
                  </div>
                </div>
                <div className='flex items-center mt-2 hover:bg-gray-100 hover:text-gray-900  py-1 cursor-pointer' onClick={()=>navigate(`/profile`)}>
                  <div className='px-3'>
                    <Person size={20} />
                  </div>
                  <div className=''>
                    <span className='text-[16px] lieading-[16px] font-normal text-[#6B7280]'>Profile</span>
                  </div>
                </div>
                <div className='flex items-center mt-2 hover:bg-gray-100 hover:text-gray-900  py-1 cursor-pointer' onClick={() => { logout(); navigate("/login") }}>
                  <div className='px-3'>
                    <Logout size={20} />
                  </div>
                  <div className='' >
                    <span className='text-[16px] lieading-[16px] font-normal text-[#6B7280]'>Logout</span>
                  </div>
                </div>

              </div>
            </div>
           </div>
            
          </div>
          
        </div>

      </div>
      <div className='w- mt-2'>
        <hr />
      </div>

    </>
  )
}

export default Header